import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Helmet } from "react-helmet"

const pronef = () => {
  return (
    <div className="px-4 sm:px-12 md:px-16 overflow-hidden lg:px-20 mx-auto mt-10 lg:mt-20 ">
      <Helmet title="Ali Hammad | Pronef">
        <title>Pronef</title>
        <meta
          name="description"
          content="Pronef is an NFT store mobile application that I have built with React Native"
        />
      </Helmet>
      <div className=" w-full flex flex-col lg:flex-row lg:justify-between">
        <div className="w-full lg:w-[50%] mx-auto mb-4 h-[16rem] sm:h-[22rem] md:h-[25rem] lg:h-[30rem] overflow-hidden ">
          <StaticImage
            className="object-cover w-full h-full animate-imagereveal mb-2"
            src="../../images/projects/webp/app-big.webp"
            alt="app"
          />
        </div>
        <div className="lg:min-w-[50%]">
          <div className="lg:ml-8 animate-textreveal  w-[30rem] overflow-clip md:overflow-visible lg:overflow-clip">
            <h2 className=" text-white text-5xl lg:text-9xl tracking-wide">
              PRONEF
            </h2>
            <p className="leading-6	 w-72 text-sm text-fuchsia-400 opacity-90 text-justify">
              is a mobile app that allow you to buy, sell, and view NFTs. It's
              like a social media application, but for NFTs. You can view the
              app on ExpoStore by scanning the QR code only on your mobile
              phone.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-20 lg:mt-56 w-full flex flex-col-reverse lg:flex-row lg:justify-between">
        <div className="lg:min-w-[50%]">
          <div className="lg:ml-8 animate-textreveal w-[30rem] overflow-clip md:overflow-visible lg:overflow-clip">
            <h2 className=" text-white text-5xl lg:text-9xl tracking-wide">
              GOAL
            </h2>
            <p className="leading-6 w-72 text-sm text-fuchsia-400 opacity-90 text-justify">
              The purpose of this application is to allow users to buy and sell
              NFTs, but this feature requires backend technologies to handle it,
              which is not a part of my specialization. So my goal is to build
              the Ui.
            </p>
          </div>
          <div className="mt-[2.4rem] lg:ml-8 animate-textreveal  w-[30rem] overflow-clip md:overflow-visible lg:overflow-clip">
            <h2 className=" text-white text-5xl lg:text-9xl tracking-wide">
              TOOLS
            </h2>
            <p className="leading-6 w-72 text-sm text-fuchsia-400 opacity-90 text-justify">
              I built the Ui with React Native. If you view the app demo, you
              can see a static app that does not require you to create an
              account. My role is to create the Ui only, which I really enjoyed.
              I build mobile apps rarely, but it's an interesting field to
              discover.
            </p>
          </div>
        </div>
        <div className="w-full mb-8 lg:w-[50%] mx-auto min-h-[16rem] sm:translate-y-6 sm:min-h-[22rem] md:min-h-[25rem] lg:min-h-[30rem] overflow-hidden ">
          <StaticImage
            className="object-cover w-full h-full mb-2"
            src="../../images/projects/webp/nft-app-sm.webp"
            alt="app"
          />
        </div>
      </div>
      <a
        href="https://expo.dev/@alihammad/nft-app"
        target="_blank"
        className="cursor-pointer"
        rel="noreferrer"
      >
        <div className="w-full mt-32 lg:mt-56 mb-10 lg:mb-20 lg:w-[50%] underline color-white bg-white underline-offset-8  mx-auto min-h-[16rem] sm:translate-y-6 sm:min-h-[22rem] md:min-h-[25rem] lg:min-h-[30rem] overflow-hidden ">
          <p className="text-black mt-5 text-2xl text-center">
            View on Expo Store
          </p>

          <StaticImage
            className="object-cover w-full h-full mt-12 scale-110"
            src="../../images/projects/webp/expo.webp"
            alt="expo"
          />
        </div>
      </a>
    </div>
  )
}

export default pronef
